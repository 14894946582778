import React, { useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import LoadingOverlay from 'react-loading-overlay';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../services/PostTemplate';
import { handlePutRequest } from '../../services/PutTemplate';
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { handleGetRequest } from '../../services/GetTemplate';
import { Dropdown } from 'primereact/dropdown';
import { baseURL } from '../../Config';
import { toast } from 'react-toastify';
import { TreeSelect } from 'primereact/treeselect';

const AddEditWarehouse = () => {

    const location = useLocation()
    const { isEditable, rowData } = location?.state;
    const [saveBtnLoading, setSaveBtnLoading] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [WHStatus, setWHStatus] = useState()
    const [selectedFiles, setSelectedFiles] = useState([])
    const [allCountries, setAllCountries] = useState([])
    const [allModulesList, setAllModulesList] = useState([])

    const roletypeid = localStorage.getItem("roletypeid")


    const history = useHistory()

    const dispatch = useDispatch()

    const validationSchema = Yup.object().shape({
        warehouse_id: Yup.mixed().required("This field is required."),
        warehouse_name: Yup.mixed().required("This field is required."),
        warehouse_poc: Yup.mixed().required("This field is required."),
        country_code: Yup.mixed().required("This field is required."),
        contact_no: Yup.mixed().required("This field is required."),
        // zip_code: Yup.mixed().required("This field is required."),
        // address: Yup.mixed().required("This field is required."),
        // location: Yup.mixed().required("This field is required."),
    });

    const formik = useFormik({

        validationSchema: validationSchema,
        initialValues: {
            warehouse_id: "",
            warehouse_name: "",
            warehouse_poc: "",
            contact_no: "",
            address: "",
            location: "",
            country_code: "",
            zip_code: "",
        },

        onSubmit: async (data) => {

            if (selectedFiles?.length === 0) {
                toast.warn('Please add logo')
            }
            else {

                if (isEditable) {
                    setSaveBtnLoading(true);

                    let obj = {
                        obj_id: rowData?.obj_id,
                        name: formik.values.warehouse_name,
                        warehouse_id: formik.values.warehouse_id,
                        warehouse_poc: formik.values.warehouse_poc,
                        contact_no: formik.values.contact_no,
                        address: formik.values.address,
                        location: formik.values.location,
                        country_id: formik.values.country_code,
                        zip_code: formik.values.zip_code,
                        attachments: selectedFiles,
                        isActive: WHStatus,
                        industry_ids: [
                            0
                        ],
                    }

                    const response = await dispatch(handlePutRequest(obj, "/api/Setups/UpdateWarehouse", true, true));
                    if (response?.statusCode == 200) {
                        history.push('./warehouse')
                        setSaveBtnLoading(false);
                    }
                    setSaveBtnLoading(false);
                }
                else {
                    setSaveBtnLoading(true);

                    let obj = {
                        name: formik.values.warehouse_name,
                        warehouse_id: formik.values.warehouse_id,
                        warehouse_poc: formik.values.warehouse_poc,
                        contact_no: formik.values.contact_no,
                        address: formik.values.address,
                        location: formik.values.location,
                        country_id: formik.values.country_code,
                        zip_code: formik.values.zip_code,
                        attachments: selectedFiles,
                        industry_ids: [
                            0
                        ],
                    }

                    const response = await dispatch(handlePostRequest(obj, "/api/Setups/AddWarehouse", true, true));
                    if (response?.statusCode == 200) {
                        history.push('./warehouse')
                        setSaveBtnLoading(false);
                    }

                    setSaveBtnLoading(false);
                }
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const ResetForm = () => {
        formik.setFieldValue('warehouse_id', '')
        formik.setFieldValue('warehouse_name', '')
        formik.setFieldValue('warehouse_poc', '')
        formik.setFieldValue('contact_no', '')
        formik.setFieldValue('location', '')
        formik.setFieldValue('address', '')
        formik.setFieldValue('country_code', '')
        formik.setFieldValue('zip_code', '')
    }

    //Get WH By Id
    const getWHById = async (rowData) => {
        setIsActive(true)
        const response = await dispatch(handleGetRequest(`/api/Setups/GetWarehouseByid?obj_id=${rowData?.obj_id}`, false));
        const keyData = response[0]
        if (keyData) {
            setWHStatus(keyData?.status)
            formik.setFieldValue('warehouse_id', keyData?.warehouse_id)
            formik.setFieldValue('warehouse_name', keyData?.obj_Name)
            formik.setFieldValue('warehouse_poc', keyData?.warehouse_poc)
            formik.setFieldValue('contact_no', keyData?.contact_no)
            formik.setFieldValue('location', keyData?.location)
            formik.setFieldValue('address', keyData?.address)
            formik.setFieldValue('zip_code', keyData?.zip_code)
            formik.setFieldValue('country_code', keyData?.country_id)

            const selectedFiles = [];

            // Split the keydata.link by commas to get individual entries
            const path = keyData?.logo_img;
            if (path) {
                selectedFiles.push({ fileType: null, fileName: null, base64Data: null, attachment_type: null, path: path });
            }

            setSelectedFiles(selectedFiles);
        }
        setIsActive(false)
    };

    //Get Countries List
    const getCountriesList = async () => {

        setIsActive(true);

        const response = await dispatch(handleGetRequest(`/api/Setups/GetAllCountries`, true));
        if (response) {
            setAllCountries(response);
        }
        setIsActive(false);
    };

    //Get Modules List
    const getModulesList = async () => {

        setIsActive(true);
        const response = await dispatch(handleGetRequest(`/api/Authentication/Getallmodulebylist?roletype_id=${roletypeid}`, true));
        if (response) {
            setAllModulesList(response);
        }
        setIsActive(false);
    };
    
    useEffect(() => {
        getCountriesList()
        getModulesList()
    }, [])

    useEffect(() => {
        if (isEditable, rowData) {
            getWHById(rowData);
        }
    }, [isEditable, rowData]);

    // Generate WH Id
    const handleGenerateWHId = () => {
        const newWHId = generateRandomWHId();
        formik.setFieldValue('warehouse_id', newWHId);
    };

    const generateRandomWHId = () => {
        const prefix = "wh-";
        const length = 4;
        const charset = "0123456789";
        let userId = "";

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            userId += charset[randomIndex];
        }

        return prefix + userId;
    };

    const countryOptionTemplate = (option) => {
        return (
            <div className="country-option">
                <img
                    alt="country-flag"
                    src={option?.country_flag}
                    style={{ width: '20px', height: '14px', marginRight: '8px' }}
                />
                <span>{option?.country_name}</span>
            </div>
        );
    };

    //File Handling
    // Function to handle icon click and file input creation
    const handleIconClick = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';
        fileInput.onchange = handleFileUpload;  // Use handleFileUpload for file input change
        fileInput.click();
    };

    // Function to handle file upload
    const handleFileUpload = (event) => {
        const files = Array.from(event.target.files || event.files);
        const updatedFiles = files.map((file) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;

                setSelectedFiles((prevFiles) => [
                    { fileType: file.type, fileName: file.name, base64Data: base64String, attachment_type: "images", path: null }
                ]);
            };
            reader.readAsDataURL(file);
            return file;
        });
    };

    // Function to handle drag and drop file upload
    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            handleFileUpload({ files: event.dataTransfer.files });
            event.dataTransfer.clearData();
        }
    };

    // Function to prevent default behavior on drag over
    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    // Function to remove a selected image file
    const handleRemoveImage = (file) => {
        setSelectedFiles([]);
    };



    return (

        <>
            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading your content...'
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: 'rgb(38 41 51 / 78%)',
                        width: '107.9%',
                        height: '125%',
                        top: '-27px',
                        left: '-35px'
                    })
                }}
            >

                <div className='main-form-card'>
                    <div className='form-header flex flex-row justify-content-between'>
                        <div className='form-header-content'>
                            <h4 style={{ fontWeight: '600', letterSpacing: '1px', color: 'black' }}>{isEditable ? "Edit" : "Add New"} Warehouse</h4>
                            <h6 style={{ color: 'gray' }}> Add Details</h6>
                        </div>
                        <div className='mt-4 mr-3'>
                            <Button className='Back-Button mr-3' icon="pi pi-arrow-left" label='Go Back' onClick={() => history.goBack()}>
                            </Button>
                        </div>
                    </div>
                    <div className='form-div'>
                        <form onSubmit={formik.handleSubmit}>

                            <div className="card-form">
                                <div className="card-nested-form">

                                    <div className="grid p-3">
                                        <div className="col-12 lg:col-4 md:col-4 p-5">
                                            <div className='flex flex-column'>
                                                <span style={{ fontWeight: "bold", fontSize: "18px", color: 'black' }}>Warehouse Logo</span>
                                                <span style={{ color: "light", fontSize: "14px" }}>This logo will be displayed on warehouse profile</span>
                                            </div>
                                        </div>
                                        <div className="settings-header col-12 lg:col-2 md:col-2 p-3 flex">
                                            {selectedFiles?.length ?
                                                <div style={{ position: 'relative', display: 'inline-block' }}>
                                                    <img src={selectedFiles[0]?.base64Data || `${baseURL}/${selectedFiles[0]?.path}`} alt="Uploaded" style={{ width: '120px', height: '120px', borderRadius: "50%" }} />
                                                    <i className="pi pi-times" style={{ position: 'absolute', top: '0', right: '0', cursor: 'pointer', fontSize: '1em', color: 'red', padding: '5px', background: 'rgba(255, 255, 255, 0.8)', borderRadius: '50%' }} onClick={handleRemoveImage}></i>
                                                </div>
                                                :
                                                null}
                                        </div>
                                        <div className="settings-header col-12 lg:col-6 md:col-6 p-3">
                                            <div>
                                                <div className="flex align-items-center flex-column dp-scrollable"
                                                    onDrop={handleDrop} onDragOver={handleDragOver}
                                                    style={{ border: '2px dashed #ccc', padding: '20px', width: '100%', textAlign: 'center' }}>
                                                    <div style={{ backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <i className="pi pi-cloud-upload" style={{ fontSize: '2em', cursor: 'pointer' }} onClick={handleIconClick}></i>
                                                    </div>
                                                    <span style={{ fontSize: '1.2em', color: 'black' }} className="mb-1 image-template">
                                                        <span style={{ fontWeight: "bold" }}> Click to Upload </span> or drag and drop
                                                    </span>
                                                    <span style={{ fontSize: '1em', color: 'gray' }} className="mb-2 image-template">
                                                        JPG or PNG (Recommended size 500x500px)
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="grid mt-2">
                                        <div className="col-12 lg:col-4 md:col-4">
                                            <div className="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Warehouse Id </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <div style={{ position: 'relative' }}>
                                                    <InputText
                                                        disabled
                                                        type='text'
                                                        placeholder='Auto Generate WH Id'
                                                        id='warehouse_id'
                                                        name="warehouse_id"
                                                        value={formik.values.warehouse_id}
                                                        onChange={formik.handleChange}
                                                        className="p-inputtext-sm"
                                                        autoComplete="off"
                                                    />
                                                    <Button
                                                        tooltip='Auto Generate Warehouse Id'
                                                        tooltipOptions={{ position: "top" }}
                                                        type="button"
                                                        icon="pi pi-refresh"
                                                        className='auto-generate-button'
                                                        onClick={handleGenerateWHId}
                                                        style={{
                                                            background: '#8d7ad5',
                                                            borderColor: "#8d7ad5",
                                                            position: 'absolute',
                                                            top: '30%',
                                                            right: '8px',
                                                            transform: 'translateY(-50%)',
                                                        }}
                                                    />
                                                </div>
                                                <span className='ml-2'>
                                                    {getFormErrorMessage("warehouse_id")}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-12 lg:col-4 md:col-4">
                                            <div className="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Warehouse Name </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText className="p-inputtext-sm" maxLength={30}
                                                    keyfilter={/^[a-zA-Z0-9 ]*$/} id="warehouse_name" name="warehouse_name" placeholder='Enter Warehouse Name' value={formik.values.warehouse_name} onChange={formik.handleChange} type="text" />
                                                <span className='ml-2'>
                                                    {getFormErrorMessage("warehouse_name")}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-12 lg:col-4 md:col-4">
                                            <div className="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Warehouse POC </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText maxLength={30} className="p-inputtext-sm" id="warehouse_poc" name="warehouse_poc" placeholder='Enter POC' value={formik.values.warehouse_poc} onChange={formik.handleChange} type="text" />
                                                <span className='ml-2'>
                                                    {getFormErrorMessage("warehouse_poc")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="grid">
                                        <div className="col-12 lg:col-4 md:col-4">
                                            <div className="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Country </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <Dropdown
                                                    filter
                                                    filterBy='country_name'
                                                    className="p-inputtext-sm"
                                                    options={allCountries}
                                                    optionLabel='country_name'
                                                    optionValue='id'
                                                    id="country_code"
                                                    name="country_code"
                                                    placeholder='--Select Country--'
                                                    value={formik.values.country_code}
                                                    onChange={(e) => formik.setFieldValue('country_code', e.value)}
                                                    itemTemplate={countryOptionTemplate}
                                                />
                                                <span className='ml-2'>
                                                    {getFormErrorMessage("country_code")}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-12 lg:col-4 md:col-4">
                                            <div className="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Contact No. </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText maxLength={15} minLength={7} keyfilter={/^[0-9.+]+/} className="p-inputtext-sm" id="contact_no" name="contact_no" placeholder='Enter Warehouse Contact No.' value={formik.values.contact_no} onChange={formik.handleChange} type="tel" />
                                                <span className='ml-2'>
                                                    {getFormErrorMessage("contact_no")}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-12 lg:col-4 md:col-4">
                                            <div className="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Zip Code </label>
                                                </div>
                                                <InputText keyfilter={/^[0-9.+]+/} className="p-inputtext-sm" id="zip_code" name="zip_code" placeholder='Enter Zip Code' value={formik.values.zip_code} onChange={formik.handleChange} type="text" />
                                                <span className='ml-2'>
                                                    {getFormErrorMessage("zip_code")}
                                                </span>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="grid">
                                        <div className="col-12 lg:col-4 md:col-4">
                                            <div className="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Location </label>
                                                </div>
                                                <InputText maxLength={100} className="p-inputtext-sm" id="location" name="location" placeholder='Enter Warehouse Location' value={formik.values.location} onChange={formik.handleChange} type="text" />
                                                <span className='ml-2'>
                                                    {getFormErrorMessage("location")}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-12 lg:col-4 md:col-4">
                                            <div className="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Address </label>
                                                </div>
                                                <InputText maxLength={100} className="p-inputtext-sm" id="address" name="address" placeholder='Enter Warehouse Addres' value={formik.values.address} onChange={formik.handleChange} type="text" />
                                                <span className='ml-2'>
                                                    {getFormErrorMessage("address")}
                                                </span>
                                            </div>
                                        </div>
                                        {/* <div className="col-12 lg:col-4 md:col-4">
                                            <div className="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Assign Modules </label>
                                                </div>
                                                <TreeSelect options={allModulesList} optionLabel='contentName' className="p-inputtext-sm" id="address" name="address" placeholder='-- Select Modules --' value={formik.values.address} onChange={formik.handleChange} />
                                                <span className='ml-2'>
                                                    {getFormErrorMessage("address")}
                                                </span>
                                            </div>
                                        </div> */}
                                    </div>

                                    <div className='col-12 d-flex flex-row text-center mt-4 pb-2'>
                                        <Button className="Cancel-Button" label="Reset" type="button" onClick={ResetForm} />
                                        <Button className="Save-Button ml-2" label={isEditable ? "Update" : "Add"} loading={saveBtnLoading} type="submit" />
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </LoadingOverlay>
        </>
    )
}

export default AddEditWarehouse