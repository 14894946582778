import React, { useEffect, useRef, useState } from 'react';
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode } from "primereact/api";
import LoadingOverlay from "react-loading-overlay";
import { Triangle } from "react-loader-spinner";
import { InputText } from "primereact/inputtext";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { Checkbox } from 'primereact/checkbox';
import { handleGetRequest } from '../../../services/GetTemplate';
import { Dropdown } from 'primereact/dropdown';

const InventoryByUsers = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const reportRef = useRef();
    const [isActive, setIsActive] = useState(false);
    const [fromDate, setFromDate] = useState(moment().subtract(30, 'days').toDate());
    const [toDate, setToDate] = useState(moment().toDate());
    const [invWhList, setInvWhList] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [warehouseid, setWarehouseId] = useState(null);
    const [visible, setVisible] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [allWH, setAllWH] = useState([])

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [columnVisibility, setColumnVisibility] = useState({
        name: true,
        category_name: true,
        subcategory_name: true,
        vendoR_NAME: true,
        min_t_value: true,
        warehouse: true,
        description: true,
        brand_name: true,
        unit: true,
        bal_qty: true,
        userName: true,
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const exportCSV = () => {
        // Filter out only the visible columns
        const visibleColumns = Object.keys(columnVisibility).filter(column => columnVisibility[column]);

        // Get the data based on the selected rows or all rows if none are selected
        const dataToExport = selectedProduct.length > 0 ? selectedProduct : invWhList;

        // Filter the data to include only the visible columns
        const filteredData = dataToExport.map(row => {
            let filteredRow = {};
            visibleColumns.forEach(column => {
                filteredRow[column] = row[column];
            });
            return filteredRow;
        });

        // Convert filtered data to CSV format
        const csvContent = [
            // Add the header row with the visible columns
            visibleColumns.join(','),
            // Add each row's data, joining values with commas
            ...filteredData.map(row => visibleColumns.map(column => row[column]).join(','))
        ].join('\n');

        // Create a Blob from the CSV content and generate a download link
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        // Create a temporary anchor element and trigger a download
        const link = document.createElement('a');
        link.href = url;
        let currentDate = new Date();

        let dateString = currentDate.toISOString().slice(0, 10);

        let timeString = currentDate.toTimeString().slice(0, 8);

        let filename = `report-${dateString}_${timeString}.csv`;

        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    const handleColumnCheckboxChange = (event, column) => {
        setColumnVisibility(prevState => ({
            ...prevState,
            [column]: event.target.checked
        }));
    };

    const columnHeaderCheckbox = (columnKey, columnHeader) => (
        <div className="flex align-items-center">
            <Checkbox
                type="checkbox"
                id={`column-${columnKey}`}
                checked={columnVisibility[columnKey]}
                onChange={(e) => handleColumnCheckboxChange(e, columnKey)}
            />
            <label htmlFor={`column-${columnKey}`} className="ml-2">{columnHeader}</label>
        </div>
    );

    const invWarehouseDetail = () => (
        <>
            <div className='flex flex-column md:flex-row align-items-start md:justify-content-between md:align-items-center'>
                <div className='flex flex-column sm:flex-row align-items-center  sm:mt-0 mt-3 '>
                    <div className="w-12rem my-2 xl:my-0 sm:mr-4">
                        <label name="fromDate">From Date</label>
                        <Calendar maxDate={toDate} className="calendar" value={fromDate} name="fromDate" showIcon placeholder="mm/dd/yyyy" onChange={(e) => setFromDate(e?.target?.value)} />
                    </div>
                    <div className="w-12rem my-2 xl:my-0">
                        <label name="toDate">To Date</label>
                        <Calendar minDate={fromDate} maxDate={new Date()} className="calendar" value={toDate} name="toDate" showIcon placeholder="mm/dd/yyyy" onChange={(e) => setToDate(e?.target?.value)} />
                    </div>

                </div>
                <div className="">
                    <Button
                        label="Download"
                        className="Add__New-Button md:ml-3 ml-0 mt-2 md:mt-0"
                        icon="pi pi-download"
                        onClick={() => exportCSV()}
                    />
                    <Dropdown
                        showClear
                        filter
                        filterBy="obj_Name"
                        placeholder='-- Select Warehouse --'
                        id="warehouseid"
                        name="warehouseid"
                        options={allWH}
                        optionLabel='obj_Name'
                        optionValue='obj_id'
                        value={warehouseid}
                        onChange={(e) => setWarehouseId(e.value)}
                        className="custom-dropdown ml-4"
                    ></Dropdown>
                </div>
            </div>
            <hr className='mt-4 mb-0'></hr>
        </>
    )

    const getInvList = async () => {

        setIsActive(true);
        const from_Date = fromDate ? moment(fromDate).format('YYYY-MM-DD') : null;
        const to_Date = toDate ? moment(toDate).format('YYYY-MM-DD') : null;
        const response = await dispatch(handleGetRequest(`/api/Reporting/GetInventoryBylocation?fromdate=${from_Date}&todate=${to_Date}&warehouse_id=${warehouseid || 0}`));
        if (response?.statusCode === "200") {
            setInvWhList(response?.body);
        }
        setIsActive(false);
    }

    //Get WH List
    const getWHList = async () => {

        setIsActive(true);
        const response = await dispatch(handleGetRequest(`/api/Setups/GetAllWarehouse`, true));
        if (response) {
            setAllWH(response);
        }
        setIsActive(false);
    };

    const showDescription = (event, rowData) => {
        event.stopPropagation();
        setDialogContent(rowData?.description)
        setVisible(true);
    }

    const descriptionTemplate = (rowData) => (
        rowData?.description?.length > 20 ?
            <div className='flex'>
                <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '11rem', cursor: 'pointer' }}>{rowData?.description} </span>
                <i className='pi pi-external-link text-purple-500' onClick={(event) => showDescription(event, rowData)} ></i>
            </div>
            :
            rowData?.description ?
                <div style={{ whiteSpace: 'nowrap' }}>{rowData?.description} </div>
                :
                <span>N/A</span>
    )

    useEffect(() => {
        if ((fromDate && toDate) || warehouseid) {
            getInvList();
            getWHList()
        }
    }, [fromDate, toDate, warehouseid]);

    return (
        <>
            <div>
                <div className="flex flex-column align-items-start md:flex-row  md:justify-content-between md:align-items-center">
                    <h4 className='mb-0' style={{ fontWeight: '600', letterSpacing: '0.5px', color: 'black' }}>
                        Inventory by Users List
                    </h4>
                    <div className='flex flex-column align-items-start  md:flex-row md:align-items-center mt-2 md:mt-0 '>
                        <span className="p-input-icon-left mr-3 mb-1">
                            <i className="pi pi-search" />
                            <InputText
                                placeholder="Search"
                                value={globalFilterValue}
                                onChange={onGlobalFilterChange}
                                className="search-input"
                            />
                        </span>
                        <div className="mt-2 md:mt-0 ml-0 md:ml-3">
                            <Button className='Back-Button' icon="pi pi-arrow-left" label='Go Back' onClick={() => history.goBack()}>
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="card mt-3">
                    <LoadingOverlay
                        active={isActive}
                        spinner={<Triangle
                            height="120"
                            width="120"
                            color="#755fc8"
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />}
                        styles={{
                            overlay: (base) => ({
                                ...base,
                                background: 'rgb(38 41 51 / 78%)',
                            })
                        }}
                    >
                        <DataTable
                            ref={reportRef}
                            responsive={true}
                            filters={filters}
                            value={invWhList}
                            selection={selectedProduct}
                            onSelectionChange={(e) => setSelectedProduct(e?.value)}
                            header={invWarehouseDetail}
                            globalFilterFields={[
                                "name",
                                "category_name",
                                "subcategory_name",
                                "vendoR_NAME",
                                "min_t_value",
                                "warehouse",
                                "description",
                                "brand_name",
                                "unit",
                                "bal_qty",
                                "userName",
                            ]}
                            responsiveLayout="scroll"
                            stripedRows
                            paginator
                            rows={20}
                            emptyMessage="No records found"
                            paginatorClassName="custom-paginator"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                        >
                            <Column className='table_checkbox' selectionMode="multiple" exportable={true}></Column>
                            <Column field='warehouse' header={columnHeaderCheckbox('warehouse', 'Warehouse')} body={(rowData) => rowData?.warehouse || 'N/A'}></Column>
                            <Column field='userName' header={columnHeaderCheckbox('userName', 'Username')} body={(rowData) => rowData?.userName || 'N/A'}></Column>
                            <Column field='name' header={columnHeaderCheckbox('name', 'Inventory Name')} body={(rowData) => rowData?.name || 'N/A'}></Column>
                            <Column field='category_name' header={columnHeaderCheckbox('category_name', 'Category')} body={(rowData) => rowData?.category_name || 'N/A'}></Column>
                            <Column field='subcategory_name' header={columnHeaderCheckbox('subcategory_name', 'Sub Category')} body={(rowData) => rowData?.subcategory_name || 'N/A'}></Column>
                            <Column field='vendoR_NAME' header={columnHeaderCheckbox('vendoR_NAME', 'Vendor')} body={(rowData) => rowData?.vendoR_NAME || 'N/A'}></Column>
                            <Column field='brand_name' header={columnHeaderCheckbox('brand_name', 'Brand')} body={(rowData) => rowData?.brand_name || 'N/A'}></Column>
                            <Column field='unit' header={columnHeaderCheckbox('unit', 'Unit')} body={(rowData) => rowData?.unit || 'N/A'}></Column>
                            <Column field='min_t_value' header={columnHeaderCheckbox('min_t_value', 'Min Threshold Value')} body={(rowData) => rowData?.min_t_value || 'N/A'}></Column>
                            <Column field='bal_qty' header={columnHeaderCheckbox('bal_qty', 'Quantity')} body={(rowData) => rowData?.bal_qty || 'N/A'}></Column>
                            <Column field='description' header={columnHeaderCheckbox('description', 'Description')} body={descriptionTemplate}></Column>
                        </DataTable>
                    </LoadingOverlay>
                    <Dialog header="Description" visible={visible} style={{ width: '40vw' }} onClick={(event) => event.stopPropagation()} onHide={() => { if (!visible) return; setVisible(false); }}>
                        <p className='text-black-alpha-90'>{dialogContent}</p>
                    </Dialog>
                </div>
            </div>
        </>
    );
}

export default InventoryByUsers;
