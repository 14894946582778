import React, { useEffect, useRef, useState } from 'react'
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode } from "primereact/api";
import LoadingOverlay from "react-loading-overlay";
import { Triangle } from "react-loader-spinner";
import { InputText } from "primereact/inputtext";
import { useDispatch } from "react-redux";
import { confirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Calendar } from 'primereact/calendar';
import { Toolbar } from 'primereact/toolbar';
import moment from 'moment';
import { handleGetRequest } from '../../../services/GetTemplate';
import { Checkbox } from 'primereact/checkbox';

const InventoryValueReport = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const reportRef = useRef()
    const warehouseid = localStorage.getItem("warehouseid")
    const [isActive, setIsActive] = useState(false)
    const [fromDate, setFromDate] = useState(moment().subtract(30, 'days').toDate())
    const [toDate, setToDate] = useState(moment().toDate())
    const [inventoryValueReport, setInventoryValueReport] = useState([])
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [selectedProduct, setSelectedProduct] = useState([])
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [columnVisibility, setColumnVisibility] = useState({
        name: true,
        categoryName: true,
        sub_Category_Name: true,
        brandName: true,
        sku: true,
        unitofmeasurementName: true,
        cost: true
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const exportCSV = () => {
        // Filter out only the visible columns
        const filterColumns = Object.keys(columnVisibility).filter(column => columnVisibility[column]);

        const visibleColumns = Object.keys(columnVisibility)?.map((column, i) => {
            if (filterColumns.length === 0) {
                return column
            } else {
                return filterColumns[i]
            }
        }).filter(Boolean)


        // Get the data based on the selected rows or all rows if none are selected
        const dataToExport = selectedProduct.length > 0 ? selectedProduct : inventoryValueReport;

        // Filter the data to include only the visible columns
        const filteredData = dataToExport.map(row => {
            let filteredRow = {};
            visibleColumns.forEach(column => {
                filteredRow[column] = row[column];
            });
            return filteredRow;
        });

        // Convert filtered data to CSV format
        const csvContent = [
            // Add the header row with the visible columns
            visibleColumns.join(','),
            // Add each row's data, joining values with commas
            ...filteredData.map(row => visibleColumns.map(column => row[column]).join(','))
        ].join('\n');

        // Create a Blob from the CSV content and generate a download link
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        // Create a temporary anchor element and trigger a download
        const link = document.createElement('a');
        link.href = url;
        let currentDate = new Date();

        let dateString = currentDate.toISOString().slice(0, 10);

        let timeString = currentDate.toTimeString().slice(0, 8);

        let filename = `report-${dateString}_${timeString}.csv`;

        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    const handleColumnCheckboxChange = (event, column) => {
        setColumnVisibility(prevState => ({
            ...prevState,
            [column]: event.target.checked
        }));
    };

    const columnHeaderCheckbox = (columnKey, columnHeader) => {
        return (
            <div className="flex align-items-center">
                <Checkbox
                    type="checkbox"
                    id={`column-${columnKey}`}
                    checked={columnVisibility[columnKey]}
                    onChange={(e) => handleColumnCheckboxChange(e, columnKey)}
                />
                <label htmlFor={`column-${columnKey}`} className="ml-2">{columnHeader}</label>
            </div>
        )
    };


    const InventoryValueDetail = () => (
        <>
            <div className='flex flex-column md:flex-row align-items-start md:justify-content-between md:align-items-center'>
                <div className='flex flex-column sm:flex-row align-items-center  sm:mt-0 mt-3 '>
                    <div className="w-12rem my-2 xl:my-0 sm:mr-4">
                        <label name="fromDate">From Date</label>
                        <Calendar maxDate={toDate} className="calendar" value={fromDate} name="fromDate" showIcon placeholder="mm/dd/yyyy" onChange={(e) => setFromDate(e?.target?.value)} />
                    </div>
                    <div className="w-12rem my-2 xl:my-0">
                        <label name="toDate">To Date</label>
                        <Calendar minDate={fromDate} maxDate={new Date()} className="calendar" value={toDate} name="toDate" showIcon placeholder="mm/dd/yyyy" onChange={(e) => setToDate(e?.target?.value)} />
                    </div>

                </div>
                <div className="">
                    <Button
                        label="Download"
                        className="Add__New-Button md:ml-3 ml-0 mt-2 md:mt-0"
                        icon="pi pi-download"
                        onClick={() => exportCSV()}
                    />
                </div>
            </div>
            <hr className='mt-4 mb-0'></hr>
        </>
    )

    const getInventoryValueReport = async () => {
        setIsActive(true)
        const from_Date = fromDate ? moment(fromDate).format('YYYY-MM-DD') : null;
        const to_Date = toDate ? moment(toDate).format('YYYY-MM-DD') : null;
        const response = await dispatch(handleGetRequest(`/api/Reporting/GetInventoryReportByvalue?fromdate=${from_Date}&todate=${to_Date}&warehouse_id=${warehouseid || 0}`))
        if (response?.statusCode === "200") {
            setInventoryValueReport(response?.body)
        }
        setIsActive(false)
    }
    useEffect(() => {
        if (fromDate && toDate) {
            getInventoryValueReport()
        }
    }, [fromDate, toDate])
    return (
        <>
            <div>
                <div className="flex flex-column align-items-start md:flex-row  md:justify-content-between md:align-items-center">
                    <h4 className='mb-0' style={{ fontWeight: '600', letterSpacing: '0.5px', color: 'black' }}>
                        Inventory value report
                    </h4>
                    <div className='flex flex-column align-items-start  md:flex-row md:align-items-center mt-2 md:mt-0 '>
                        <span className="p-input-icon-left mr-3 mb-1">
                            <i className="pi pi-search" />
                            <InputText
                                placeholder="Search"
                                value={globalFilterValue}
                                onChange={onGlobalFilterChange}
                                className="search-input"
                            />
                        </span>
                        <div className="mt-2 md:mt-0 ml-0 md:ml-3">
                            <Button className='Back-Button' icon="pi pi-arrow-left" label='Go Back' onClick={() => history.goBack()}>
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="card mt-3">

                    <LoadingOverlay
                        active={isActive}
                        spinner={<Triangle
                            height="120"
                            width="120"
                            color="#755fc8"
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />}

                        // text='Loading your content...'
                        styles={{
                            overlay: (base) => ({
                                ...base,
                                background: 'rgb(38 41 51 / 78%)',
                            })
                        }}
                    >

                        <DataTable
                            ref={reportRef}
                            responsive={true}
                            filters={filters}
                            value={inventoryValueReport}
                            selection={selectedProduct}
                            onSelectionChange={(e) => setSelectedProduct(e?.value)}
                            header={InventoryValueDetail}
                            globalFilterFields={[
                                "name",
                                "unitofmeasurementName",
                                "categoryName",
                                "sub_Category_Name",
                                "brandName",
                                "sku",
                                "unitofmeasurementName",
                                "cost"
                            ]}
                            className='report_datatable'
                            responsiveLayout="scroll"
                            stripedRows
                            paginator
                            rows={20}
                            emptyMessage="No records found"
                            paginatorClassName="custom-paginator"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}

                        >
                            <Column className='table_checkbox' selectionMode="multiple" exportable={true}></Column>
                            <Column sortable field='name' header={columnHeaderCheckbox('name', 'Inventory Name')} body={(rowData) => rowData?.name || 'N/A'} ></Column>
                            <Column sortable field='categoryName' header={columnHeaderCheckbox('categoryName', 'Category')} body={(rowData) => rowData?.categoryName || 'N/A'} ></Column>
                            <Column sortable field='sub_Category_Name' header={columnHeaderCheckbox('sub_Category_Name', 'Sub Category')} body={(rowData) => rowData?.sub_Category_Name || 'N/A'}></Column>
                            <Column sortable field='brandName' header={columnHeaderCheckbox('brandName', 'Brand')} body={(rowData) => rowData?.brandName || 'N/A'} ></Column>
                            <Column sortable field='sku' header={columnHeaderCheckbox('sku', 'SKU')} body={(rowData) => rowData?.sku || 'N/A'} ></Column>
                            <Column sortable field='unitofmeasurementName' header={columnHeaderCheckbox('unitofmeasurementName', 'Unit')} body={(rowData) => rowData?.unitofmeasurementName || 'N/A'} ></Column>
                            <Column sortable field='cost' header={columnHeaderCheckbox('cost', 'Cost per unit')} body={(rowData) => rowData?.cost || 'N/A'} ></Column>
                        </DataTable>
                    </LoadingOverlay>
                </div>
            </div>

        </>
    )
}

export default InventoryValueReport
