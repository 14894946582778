//Local Url:
// let baseURL = "http://192.168.11.51:7097"

//QA Url:
let baseURL = "https://dev-wms.appinsnap.com"

//UAT Url:
// let baseURL = "https://uat-whms.appinsnap.com"

export { baseURL };
