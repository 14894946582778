import React, { useState, useEffect, useContext, useRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import LoadingOverlay from "react-loading-overlay";
import { Triangle } from "react-loader-spinner";
import { InputText } from "primereact/inputtext";
import { handleGetRequest } from "../../services/GetTemplate";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

const LowLevelList = () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const location = useLocation();
    const { warehouseId } = location.state || {};

    const [isActive, setIsActive] = useState(false)
    const [allItemsList, setAllItemsList] = useState([]);

    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    //Get Low Level List
    const getLowLevelList = async () => {

        setIsActive(true);
        const response = await dispatch(handleGetRequest(`/api/Dashboard/GetLowLevelitemsReport?warehouse_id=${warehouseId || 0}`, true));
        if (response?.statusCode == 200) {
            setAllItemsList(response?.body);
        }
        setIsActive(false);
    };

    useEffect(() => {
        getLowLevelList()
    }, [])

    return (
        <>

            <div className="flex flex-column align-items-start md:flex-row  md:justify-content-between md:align-items-center ">
                <h4 className="mb-0" style={{ fontWeight: '600', letterSpacing: '0.5px', color: 'black' }}>
                    Low level items detailed list
                </h4>
                <div className='flex flex-column align-items-start  md:flex-row md:align-items-center mt-2 md:mt-0 '>
                    <span className="p-input-icon-left mr-3 mb-1">
                        <i className="pi pi-search" />
                        <InputText
                            placeholder="Search"
                            value={globalFilterValue}
                            onChange={onGlobalFilterChange}
                            className="search-input"
                        />
                    </span>
                    <div className="mt-2 md:mt-0 ml-0 md:ml-3">
                        <Button className='Back-Button' icon="pi pi-arrow-left" label='Go Back' onClick={() => history.goBack()}>
                        </Button>
                    </div>
                </div>
            </div>
            <div className="card mt-5">

                <LoadingOverlay
                    active={isActive}
                    spinner={<Triangle
                        height="120"
                        width="120"
                        color="#755fc8"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />}

                    // text='Loading your content...'
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: 'rgb(38 41 51 / 78%)',
                        })
                    }}
                >

                    <DataTable
                        responsive={true}
                        filters={filters}
                        globalFilterFields={[
                            "warehouse",
                            "name",
                            "bal_qty",
                            "min_t_value",
                        ]}
                        responsiveLayout="scroll"
                        stripedRows
                        paginator
                        rows={20}
                        value={allItemsList}
                        emptyMessage="No records found"
                        paginatorClassName="custom-paginator"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                    >
                        <Column sortable field='warehouse' body={(rowData) => rowData?.warehouse || 'N/A'} header="Warehouse"></Column>
                        <Column sortable field='name' body={(rowData) => rowData?.name || 'N/A'} header="Item Name"></Column>
                        <Column sortable field='bal_qty' body={(rowData) => rowData?.bal_qty || 'N/A'} header="Balance Quantity"></Column>
                        <Column sortable field='min_t_value' body={(rowData) => rowData?.min_t_value || 'N/A'} header="Min threshold Value"></Column>
                    </DataTable>
                </LoadingOverlay>
            </div>
        </>
    );
};

export default LowLevelList